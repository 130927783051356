import React from "react"

export default function ShapeExclude() {
  return (
    <svg
      width="1014"
      height="1014"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1014 0H0v1014h1014V0zM695.888 891.228l-362.061-4.457c-72.984-.81-146.137-56.792-166.465-127.41L95.769 511.454c-8.458-28.959-8.68-61.364-.772-93.714 11.908-48.702 40.342-90.686 77.984-114.972l.294-.177c2.057-1.239 205.206-128.944 280.489-177.517 44.664-28.92 98.49-36.883 147.754-22.092 50.209 15.032 90.488 51.202 113.443 101.846l.565 1.277L869.888 590.6c19.934 53.779 24.367 106.888 12.924 153.695-21.613 88.4-96.705 147.469-186.924 146.933z"
        fill="#050505"
      />
    </svg>
  )
}
