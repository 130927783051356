import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { listReset } from "../reset"
import { rem } from "../utils"
import theme from "../theme"
import RichText from "../RichText"
import ShapeClipPathExclude from "../ShapeClipPathExclude"
import { useMotionDelay } from "../hooks"

export const FRAGMENT_About_us_section = graphql`
  fragment FRAGMENT_About_us_section on PRISMIC_About_us_section {
    _meta {
      uid
    }

    content
    awards {
      logo
      logoSharp {
        publicURL
        childImageSharp {
          fixed(quality: 90, height: 64) {
            ...GatsbyImageSharpFixed_noBase64
            width
            height
          }
        }
      }
      title
    }

    image
    imageSharp {
      publicURL
      childImageSharp {
        fluid(quality: 90, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    video {
      ... on PRISMIC__FileLink {
        url
      }
    }

    theme
  }
`

const SectionLayout = styled.div`
  padding: 0 ${rem(24)} ${rem(24)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding: ${rem(120)} ${rem(24)} ${rem(96)};
  }
`

const AwardsList = styled.ul`
  ${listReset};

  display: flex;
  flex-wrap: wrap;
  margin: ${rem(-12)};
  margin-top: ${rem(48)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    margin-top: ${rem(64)};
  }
`

const Award = styled.li`
  margin: ${rem(12)};
`

const MediaGroup = styled.div`
  margin: ${rem(-70)} ${rem(-24)} ${rem(64)};
  overflow: visible;

  svg {
    display: none;
  }

  .video-wrapper {
    padding-bottom: ${(2 / 3) * 100}%;
    position: relative;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .gatsby-image-wrapper > div {
    padding-bottom: ${(2 / 3) * 100}% !important;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    margin: 0;
    position: absolute;
    top: ${rem(72)};
    right: 0;
    width: ${rem(1014)};
    transform: translate(calc(100% - 40vw), -25%) scale(0.8);

    svg {
      display: block;
    }

    /* .video-wrapper,
    .gatsby-image-wrapper {
      -webkit-clip-path: url(#intro-media-clipping);
      clip-path: url(#intro-media-clipping);
    } */

    .video-wrapper {
      padding-bottom: 100%;
    }

    .gatsby-image-wrapper > div {
      padding-bottom: 100% !important;
    }
  }
`

const ContentGroup = styled.div`
  position: relative;
  text-shadow: 1px 1px 1px ${({ theme }) => theme.background};
`

export default function AboutUsSection({ data }) {
  const { content, awards, image, imageSharp, video } = data

  const motionDelay = useMotionDelay()
  const [mediaRef, nediaInView] = useInView({
    triggerOnce: true,
  })
  const [awardsRef, awardsInView] = useInView({
    triggerOnce: true,
  })

  return (
    <SectionLayout>
      <MediaGroup>
        <motion.div
          ref={mediaRef}
          initial="initial"
          animate={nediaInView ? "enter" : "initial"}
          variants={theme.motion.contentVariants}
          custom={motionDelay}
        >
          <ShapeClipPathExclude image={image} imageSharp={imageSharp} video={video} />
        </motion.div>
      </MediaGroup>

      <ContentGroup>
        <RichText render={content} />
      </ContentGroup>

      <motion.div
        ref={awardsRef}
        initial="initial"
        animate={awardsInView ? "enter" : "initial"}
        variants={theme.motion.contentVariants}
        custom={motionDelay}
      >
        <AwardsList>
          {awards.map(award => {
            const { logo, logoSharp, title } = award

            let width, height
            if (logoSharp && logoSharp.childImageSharp) {
              width = logoSharp.childImageSharp.fixed.width
              height = logoSharp.childImageSharp.fixed.height
            }

            return (
              <Award key={title} style={{ width, height }}>
                {logoSharp && logoSharp.childImageSharp ? (
                  <Img fixed={logoSharp.childImageSharp.fixed} title={title} />
                ) : (
                  <img src={logo.url} alt="" title={title} />
                )}
              </Award>
            )
          })}
        </AwardsList>
      </motion.div>
    </SectionLayout>
  )
}
