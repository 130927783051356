import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { buttonReset } from "./reset"

const Button = styled.button.attrs({
  type: "button",
})`
  ${buttonReset};

  color: currentColor;
  padding: 1em;
  transform: translateX(1em);

  &:focus {
    outline: none;
  }
`

const Path = props => (
  <motion.path
    stroke="currentColor"
    strokeLinecap="round"
    strokeWidth="2"
    {...props}
  />
)

export default function MobileNavToggle({ isOpen, onToggle }) {
  return (
    <Button
      onClick={onToggle}
      aria-label={isOpen ? "Close navigation" : "Open navigation"}
    >
      <motion.svg
        width="23"
        height="23"
        viewBox="0 0 23 18"
        initial={false}
        animate={isOpen ? "open" : "closed"}
      >
        <Path
          variants={{
            closed: { d: "M 2 2.5 L 20 2.5" },
            open: { d: "M 3 16.5 L 17 2.5" },
          }}
        />
        <Path
          d="M 2 9.423 L 20 9.423"
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 },
          }}
          transition={{ duration: 0.1 }}
        />
        <Path
          variants={{
            closed: { d: "M 2 16.346 L 20 16.346" },
            open: { d: "M 3 2.5 L 17 16.346" },
          }}
        />
      </motion.svg>
    </Button>
  )
}
