import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { motion, AnimatePresence } from "framer-motion"
import { rem } from "../utils"
import theme from "../theme"
import RichText from "../RichText"
import ButtonGroup from "../ButtonGroup"
import { useMotionDelay } from "../hooks"

export const FRAGMENT_Solution_section = graphql`
  fragment FRAGMENT_Solution_section on PRISMIC_Solution_section {
    _meta {
      uid
    }

    content
    videos {
      poster
      posterSharp {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
        publicURL
      }
      link {
        ... on PRISMIC__FileLink {
          url
        }
      }
      embed
      solution_category {
        ... on PRISMIC_Solution_category {
          _meta {
            uid
          }
          name
          order
        }
      }
    }

    theme
  }
`

const SectionLayout = styled.div`
  padding: 0 ${rem(24)} ${rem(24)};

  display: flex;
  flex-direction: column;

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding: ${rem(120)} ${rem(24)} ${rem(96)};
  }
`

const ContentGroup = styled.div``

const MediaGroup = styled.div`
  background-color: ${({ theme }) => theme.highlight.background};
  border: 1px solid ${({ theme }) => theme.highlight.border};
  border-radius: ${rem(4)};
  margin: ${rem(48)} ${rem(-24)} 0;
  padding: ${rem(8)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    margin: ${rem(48)} 0 0;
    max-width: ${rem(theme.screens.sm)};
  }
`

const ButtonGroupWrapper = styled.div`
  margin: 0 ${rem(-8)};

  > * {
    padding: 0 ${rem(8)};
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    margin: 0;

    > * {
      padding: 0;
    }
  }
`

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: ${(9 / 16) * 100}%;
`

const Video = styled(motion.video)`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const VideoEmbed = styled(motion.div)`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  > div[data-type="video"] {
    h1,
    p {
      display: none;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`

const videoVariants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 1,
  },
}

export default function SolutionSection({ data }) {
  const { content, videos } = data

  const videosWithCategory = videos.filter(
    video => video.solution_category !== null
  )

  const groupCategories = videosWithCategory.map(({ solution_category }) => ({
    key: solution_category._meta.uid,
    label: solution_category.name,
    order: solution_category.order,
  })).sort((a, b) => a.order - b.order)

  const [category, setCategory] = React.useState(() =>
    groupCategories[0] ? groupCategories[0].key : null
  )

  const activeVideo = videosWithCategory.find(
    ({ solution_category }) => solution_category._meta.uid === category
  )

  const motionDelay = useMotionDelay()
  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  return (
    <SectionLayout>
      <ContentGroup>
        <RichText render={content} wide />
      </ContentGroup>

      <motion.div
        ref={ref}
        initial="initial"
        animate={inView ? "enter" : "initial"}
        variants={theme.motion.contentVariants}
        custom={motionDelay}
      >
        <MediaGroup>
          <ButtonGroupWrapper>
            <ButtonGroup
              categories={groupCategories}
              active={category}
              onChange={setCategory}
              style={{ marginBottom: rem(8) }}
            />
          </ButtonGroupWrapper>

          <VideoWrapper>
            <AnimatePresence exitBeforeEnter>
              {activeVideo.embed ? (
                <VideoEmbed
                  key={activeVideo.solution_category._meta.uid}
                  initial="initial"
                  animate="enter"
                  exit="exit"
                  variants={videoVariants}
                  dangerouslySetInnerHTML={{ __html: activeVideo.embed.html }}
                />
              ) : (
                <Video
                  key={activeVideo.solution_category._meta.uid}
                  initial="initial"
                  animate="enter"
                  exit="exit"
                  variants={videoVariants}
                  controls
                  poster={
                    activeVideo.posterSharp
                      ? activeVideo.posterSharp.publicURL
                      : activeVideo.poster && activeVideo.poster.url
                  }
                  preload="metadata"
                >
                  <source src={activeVideo.link.url} type="video/mp4" />
                </Video>
              )}
            </AnimatePresence>
          </VideoWrapper>
        </MediaGroup>
      </motion.div>
    </SectionLayout>
  )
}
