import React from "react"
import styled, { css } from "styled-components"
import { buttonReset } from "./reset"
import { rem } from "./utils"
import theme from "./theme"

const StyledButtonGroup = styled.div`
  display: flex;

  overflow-x: scroll;
  scroll-snap-type: x mandatory;

  > button {
    flex: 1;
    white-space: nowrap;

    outline-offset: -2px;
    &:focus {
      outline: 2px solid ${({ theme }) => theme.button.focus};
    }

    &:first-child {
      border-top-left-radius: ${rem(5)};
      border-bottom-left-radius: ${rem(5)};
    }

    &:last-child {
      border-top-right-radius: ${rem(5)};
      border-bottom-right-radius: ${rem(5)};
    }
  }

  > button + button {
    border-left-width: 0;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    overflow-x: auto;

    > button {
      white-space: normal;
    }
  }
`

const CategoryButton = styled.button.attrs({ type: "button" })`
  ${buttonReset};

  background-color: ${({ theme }) => theme.button.background.default};
  border: 1px solid ${({ theme }) => theme.button.border};
  color: ${({ theme }) => theme.button.text.default};
  cursor: pointer;
  font-size: ${rem(16)};
  padding: ${rem(12)} ${rem(32)};

  &:hover {
    background-color: ${({ theme }) => theme.button.border};
    color: ${({ theme }) => theme.button.text.hover};
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.button.background.active};
      color: ${({ theme }) => theme.button.text.active};

      pointer-events: none;
    `};
`

export default function ButtonGroup({
  categories,
  active,
  onChange,
  className,
  style,
}) {
  return (
    <StyledButtonGroup className={className} style={style}>
      {categories.map(({ key, label, reset }) => {
        const uid = reset ? null : key
        const currentlyActive = active === uid
        return (
          <CategoryButton
            key={key}
            active={currentlyActive}
            disabled={currentlyActive}
            onClick={() => onChange(uid)}
          >
            {label}
          </CategoryButton>
        )
      })}
    </StyledButtonGroup>
  )
}
