import React from "react"
import styled, { css } from "styled-components"
import { buttonReset } from "./reset"
import { rem } from "./utils"
import theme from "./theme"
import Button from "./Button"

const Message = styled.p`
  color: ${({ theme }) => theme.text.heading};
  font-size: ${rem(24)};
  line-height: 130%;
`

const StyledForm = styled.form`
  input[type="submit"] {
    margin-top: ${rem(24)};
  }

  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);

  ${({ isPending }) =>
    isPending &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & + & {
    margin-top: ${rem(16)};
  }

  @media (min-width: ${rem(460)}) {
    flex-direction: row;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    flex-direction: column;
  }

  @media (min-width: ${rem(920)}) {
    flex-direction: row;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & + * {
    margin-top: ${rem(16)};
  }

  @media (min-width: ${rem(460)}) {
    & + * {
      margin-top: 0;
      margin-left: ${rem(24)};
    }
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    & + * {
      margin-top: ${rem(16)};
    }
  }

  @media (min-width: ${rem(920)}) {
    & + * {
      margin-top: 0;
      margin-left: ${rem(24)};
    }
  }
`

const Label = styled.label`
  color: ${({ theme }) => theme.text.body};
  display: block;
  font-size: ${rem(14)};
  margin-bottom: ${rem(8)};
`

const inputStyles = ({ theme }) => css`
  background-color: ${theme.button.background.default};
  border: 1px solid ${theme.button.border};
  border-radius: ${rem(5)};
  color: ${theme.text.body};
  font: inherit;
  font-size: ${rem(16)};
  padding: 0.5em;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${theme.button.border};
  }

  &:focus,
  &:active {
    background-color: ${theme.button.border};
    border-color: ${theme.navigation.default};
  }
`

const Input = styled.input`
  ${inputStyles};
`

const TextArea = styled.textarea`
  ${inputStyles};
  resize: none;
`

const Submit = styled.input.attrs({
  type: "submit",
})`
  ${/* FIXME: Duplication - Button secondary */ ""};
  ${buttonReset};

  background-color: transparent;
  border: 1px solid ${theme.colors.accent};
  border-radius: ${rem(5)};
  color: ${theme.colors.white};
  cursor: pointer;
  margin-top: ${rem(32)};
  padding: ${rem(20)} ${rem(40)};

  outline-offset: 4px;
  &:focus {
    outline: 2px solid ${theme.colors.accent};
  }

  &:hover {
    background-color: ${theme.colors.variants.hover.accent};
    border-color: ${theme.colors.variants.hover.accent};
  }
`

const SubmitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export default function ContactForm() {
  const [formStatus, setFormStatus] = React.useState("")

  function onFormSubmit(event) {
    event.preventDefault()

    setFormStatus("pending")

    const form = event.target
    const formData = new FormData(form)

    fetch("/api/forms/", {
      method: "POST",
      body: formData,
    })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          setFormStatus("success")
        } else {
          setFormStatus("error")
        }
      })
      .catch(error => {
        setFormStatus("error")
      })
  }

  if (formStatus === "success") {
    return (
      <div>
        <Message>
          Thank you for showing interest, we will get in touch with you shortly!
        </Message>
        <Button
          secondary
          onClick={() => {
            setFormStatus("none")
          }}
        >
          Send another message
        </Button>
      </div>
    )
  } else if (formStatus === "error") {
    return (
      <div>
        <Message>Some error ocurred. Please try again.</Message>
        <Button
          secondary
          onClick={() => {
            setFormStatus("none")
          }}
        >
          Try again
        </Button>
      </div>
    )
  }

  return (
    <StyledForm
      action=""
      method="post"
      onSubmit={onFormSubmit}
      isPending={formStatus === "pending"}
    >
      <Row>
        <Col>
          <Label htmlFor="email-input">
            Your e-mail<sup style={{ color: theme.colors.accent }}>*</sup>
          </Label>
          <Input id="email-input" type="email" name="email" required />
        </Col>

        <Col>
          <Label htmlFor="name-input">Name</Label>
          <Input id="name-input" type="text" name="name" />
        </Col>
      </Row>

      <Row>
        <Col>
          <Label htmlFor="message-input">Message</Label>
          <TextArea id="message-input" name="message" rows={4} />
        </Col>
      </Row>

      <SubmitWrapper>
        <Submit value="Send" />
      </SubmitWrapper>
    </StyledForm>
  )
}
