import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { rem } from "./utils"
import Button from "./Button"
import Navigation from "./Navigation"

const StyledAside = styled.aside`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  height: 100%;
`

const CtaWrapper = styled(motion.div)`
  margin-top: auto;
  margin-bottom: ${rem(34)};
  margin-left: ${rem(34)};

  > button {
    margin-top: 0;
    padding-left: ${rem(20)};
    padding-right: ${rem(20)};
  }
`

const ctaVariants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
}

export default function Aside({
  uid,
  items,
  activeSection,
  ctaVisible,
  navigate,
}) {
  const [logoItem, ...listItems] = items

  return (
    <StyledAside>
      <Navigation
        uid={uid}
        logo={logoItem}
        items={listItems}
        activeItem={activeSection}
        navigate={navigate}
      />

      <CtaWrapper
        initial="initial"
        animate={ctaVisible ? "enter" : "initial"}
        variants={ctaVariants}
        style={{ pointerEvents: ctaVisible ? "auto" : "none" }}
        tabIndex={ctaVisible ? 0 : -1}
      >
        <Button
          onClick={() => {
            const uid = "contacts"
            const section = document.body.querySelector(
              `section[data-key="${uid}"]`
            )
            if (section) section.scrollIntoView()
          }}
        >
          Get in touch
        </Button>
      </CtaWrapper>
    </StyledAside>
  )
}
