import React from "react"
import styled from "styled-components"

const TypePath = styled.path`
  fill: ${({ theme }) => theme.logo};
`

export default React.memo(function Logo() {
  return (
    <svg width="103" height="35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <TypePath d="M4.88 7.026H0V34.5h4.88V7.026zM8.81 13.906h4.474v1.895c.953-1.858 3.447-2.563 5.356-2.563 2.09 0 4.145.893 5.428 2.563.183.186.33.41.477.632.147-.261.33-.483.512-.632 1.43-1.746 3.741-2.563 5.944-2.563 2.386 0 4.586.705 6.199 2.563 1.87 2.045 2.089 4.463 2.089 7.139V34.5h-4.88V23.532c0-1.19-.036-2.9-.734-3.94-.624-.966-1.87-1.525-3.045-1.525-1.358 0-2.641.596-3.375 1.783-.843 1.34-.77 3.348-.77 4.833v9.814H21.61V23.532c0-1.34 0-2.603-.77-3.79-.7-1.08-1.946-1.675-3.193-1.675-3.887 0-3.962 3.718-3.962 6.62V34.5H8.809V13.906zM42.919 7.026h4.88v6.88h4.22v4.386h-4.22V34.5h-4.88V7.026z" />
      <path
        d="M93.672 25.9c-.737 0-1.442-.251-1.888-.676-.18-.177-4.344-4.238-5.938-5.756-.555-.529-.842-1.218-.812-1.942.03-.729.38-1.403.992-1.9l8.167-5.982c.837-.574 1.697-.878 2.488-.878 1.334 0 2.443.875 2.76 2.175l2.087 8.797c.222.932-.3 2.169-1.118 2.646l-5.47 3.204c-.345.204-.795.313-1.268.313zm9.723-6.618l-2.086-8.803c-.534-2.19-2.395-3.66-4.63-3.66-1.185 0-2.423.423-3.583 1.224l-8.221 6.02-.027.022c-1.067.857-1.685 2.047-1.739 3.354a4.509 4.509 0 001.421 3.45c1.613 1.537 5.884 5.704 5.926 5.747l.006.006c.806.769 1.978 1.209 3.21 1.209.818 0 1.588-.197 2.227-.574l5.47-3.205c1.558-.91 2.449-3.016 2.026-4.79z"
        fill="#B5002F"
      />
      <path
        d="M88.33 17.053c-.266 0-.518-.04-.752-.116l-4.936-1.625c-1.217-.4-2.287-1.89-2.287-3.19V4.484c0-.814.21-1.479.602-1.928.351-.395.843-.605 1.43-.605.396 0 .824.094 1.27.276l8.564 3.509c.887.364 1.54 1.033 1.838 1.877.296.847.203 1.783-.258 2.633l-2.935 5.377c-.458.841-1.501 1.43-2.535 1.43zm7.542-10.094c-.483-1.38-1.523-2.457-2.931-3.035L84.38.416A5.28 5.28 0 0082.39 0c-2.329 0-3.956 1.844-3.956 4.483v7.64c0 2.16 1.588 4.377 3.614 5.042l4.94 1.625c.425.14.878.213 1.346.213 1.73 0 3.426-.982 4.22-2.44l2.931-5.376c.735-1.345.873-2.846.387-4.228z"
        fill="#E00027"
      />
      <path
        d="M71.12 15.57l13.413-5.063c.285-.103.563-.155.83-.155.917 0 1.586.601 1.787 1.604l.833 4.137 1.013 5.042c.063.319.036.595-.081.738-.108.134-.309.161-.459.161-.099 0-.203-.012-.317-.036l-16.26-3.424-.01-.003-.009-.003c-1.232-.24-2.032-.81-2.086-1.485-.045-.58.495-1.19 1.346-1.513zm19.758 5.176l-1.846-9.18c-.38-1.891-1.855-3.164-3.672-3.164-.491 0-.989.091-1.486.27l-13.428 5.07c-1.694.64-2.71 2.017-2.587 3.505.063.775.431 1.485 1.06 2.053.64.575 1.503.972 2.573 1.185l16.254 3.423c.24.052.48.076.707.076.8 0 1.493-.31 1.949-.878.494-.607.662-1.445.476-2.36z"
        fill="#EC6D1F"
      />
      <path
        d="M60.932 20.002c-.932.258-1.55.313-1.831-.03-.198-.244-.168-.754.315-1.774l5.473-11.519c.413-.865.995-1.364 1.594-1.364.513 0 1.061.347 1.55.975 1.612 2.081 6.048 7.767 6.09 7.822.39.504.534 1.005.405 1.419-.129.41-.53.735-1.133.917l-12.463 3.554zM75.634 12.9c-.045-.058-4.478-5.74-6.088-7.818-.86-1.11-1.945-1.72-3.06-1.72-1.37 0-2.584.9-3.33 2.47L57.683 17.35c-.89 1.871-.605 3.022-.213 3.657.453.732 1.283 1.133 2.332 1.133.507 0 1.067-.091 1.667-.27l12.45-3.554.01-.003c1.216-.365 2.104-1.166 2.427-2.196.327-1.03.063-2.202-.722-3.217z"
        fill="#F08400"
      />
    </svg>
  )
})
