import React from "react"
import styled, { css } from "styled-components"
import { buttonReset } from "./reset"
import { rem } from "./utils"
import theme from "./theme"

const StyledButton = styled.button.attrs({ type: "button" })`
  ${buttonReset};

  background: ${theme.colors.accent};
  border: 1px solid ${theme.colors.accent};
  border-radius: ${rem(5)};
  color: ${theme.colors.white};
  cursor: pointer;
  margin-top: ${rem(32)};
  padding: ${rem(20)} ${rem(40)};

  outline-offset: 4px;
  &:focus {
    outline: 2px solid ${theme.colors.accent};
  }

  /* FIXME: Refaktēt par ThemeProvider theme */
  &:hover {
    background-color: ${theme.colors.variants.hover.accent};
    border-color: ${theme.colors.variants.hover.accent};
  }

  ${({ secondary }) =>
    secondary &&
    css`
      background-color: transparent;
    `};
`

export default function Button({
  secondary,
  disabled,
  children,
  className,
  style,
  onClick,
  title,
  innerRef,
}) {
  return (
    <StyledButton
      secondary={secondary}
      disabled={disabled}
      className={className}
      style={style}
      onClick={onClick}
      title={title}
      ref={innerRef}
    >
      {children}
    </StyledButton>
  )
}
