import React from "react"
import styled, { css } from "styled-components"
import { rem } from "./utils"
import Logo from "./Logo"
import MobileNavToggle from "./MobileNavToggle"

const barHeight = 70

const StyledTopBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${rem(barHeight)};
  padding: 0 ${rem(24)};

  color: ${({ theme }) => theme.logo};

  &::before {
    background-color: ${({ theme }) => theme.background};
    opacity: 0.9;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);

    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  ${({ menuOpen }) =>
    menuOpen &&
    css`
      &::before {
        opacity: 1;
      }
    `};
`

export default function TopBar({ mobileMenuOpen, toggleMobileMenu }) {
  return (
    <StyledTopBar menuOpen={mobileMenuOpen}>
      <Logo />
      <MobileNavToggle isOpen={mobileMenuOpen} onToggle={toggleMobileMenu} />
    </StyledTopBar>
  )
}
