import React from "react"

// https://joshwcomeau.com/react/the-perils-of-rehydration/

export default function ClientOnly({ children }) {
  const [mounted, setMounted] = React.useState(false)

  React.useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) {
    return null
  }

  return children
}
