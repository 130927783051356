import React from "react"
import styled, { css } from "styled-components"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { RichText as PrismicRichText, Elements } from "prismic-reactjs"
import { rem, fluidValue } from "./utils"
import theme from "./theme"

const StyledRichText = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.text.heading};
    max-width: ${rem(480)};
  }

  p {
    color: ${({ theme }) => theme.text.body};
    max-width: ${rem(400)};
  }

  ${({ wide }) =>
    wide &&
    css`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        max-width: ${rem(600)};
      }
    `};

  p {
    max-width: ${rem(500)};
  }
`

const StyledHeading = styled(motion.h2)`
  font-size: ${rem(40)};
  font-weight: 300;
  line-height: 115%;
  margin-top: 0;
  margin-bottom: 0.4em;

  strong {
    font-weight: 700;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    font-size: ${fluidValue(40, 60, theme.screens.sm, theme.screens.lg)};
  }

  @media (min-width: ${rem(theme.screens.lg)}) {
    font-size: ${rem(60)};
  }
`

const headingVariants = {
  initial: { opacity: 0, x: 50 },
  enter: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.2,
      type: "spring",
      damping: 50,
      stiffness: 150,
    },
  },
}

function Heading(props) {
  const [ref, inView] = useInView({
    triggerOnce: true,
  })
  return (
    <StyledHeading
      ref={ref}
      {...props}
      initial="initial"
      animate={inView ? "enter" : "initial"}
      variants={headingVariants}
    />
  )
}

const StyledParagraph = styled(motion.p)`
  font-size: ${rem(16)};
  line-height: 150%;
  margin: 0;

  & + & {
    margin-top: 1.5em;
  }
`

const paragraphVariants = {
  initial: { opacity: 0, y: 20 },
  enter: (i = 0) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.8 + 0.1 * i,
      type: "spring",
      damping: 150,
      stiffness: 250,
    },
  }),
}

function Paragraph(props) {
  const [ref, inView] = useInView({
    triggerOnce: true,
  })
  return (
    <StyledParagraph
      ref={ref}
      {...props}
      initial="initial"
      animate={inView ? "enter" : "initial"}
      variants={paragraphVariants}
    />
  )
}

function htmlSerializer(type, element, content, children, key) {
  switch (type) {
    case Elements.heading2: // Heading 2
      return <Heading key={key}>{children}</Heading>

    case Elements.paragraph: // Paragraph
      const offset = key - 1
      return (
        <Paragraph key={key} custom={offset}>
          {children}
        </Paragraph>
      )

    default:
      return null
  }
}

export default function RichText({ render, wide, className, style }) {
  return (
    <StyledRichText wide={wide} className={className} style={style}>
      <PrismicRichText render={render} htmlSerializer={htmlSerializer} />
    </StyledRichText>
  )
}
