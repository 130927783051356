import { rem } from "./utils"
import theme from "./theme"

export function useMotionDelay() {
  const widerThanSmallScreen =
    typeof window !== "undefined" &&
    window.matchMedia(`(min-width: ${rem(theme.screens.sm)})`)

  const motionDelay =
    widerThanSmallScreen && widerThanSmallScreen.matches
      ? { delay: 1 }
      : { delay: 0.5 }

  return motionDelay
}
