import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { motion, useSpring } from "framer-motion"
import { listReset } from "./reset"
import { rem } from "./utils"
import theme from "./theme"
import Logo from "./Logo"

const Wrapper = styled.div`
  margin-top: ${rem(41)};
  padding-left: ${rem(34)};
  position: relative;
`

const indicatorHeight = 56

const Indicator = styled(motion.div)`
  background-color: ${theme.colors.accent};
  width: ${rem(5)};
  height: ${rem(indicatorHeight)};
  position: absolute;
  top: 0;
  left: 0;
`

const Nav = styled.nav`
  a {
    color: ${({ theme }) => theme.navigation.default};
    display: inline-block;
    margin: -0.5em;
    margin-top: 0;
    padding: 0.5em;
    text-decoration: none;

    &.active {
      color: ${({ theme }) => theme.navigation.active};
      pointer-events: none;
    }

    &:hover {
      color: ${({ theme }) => theme.navigation.active};
    }
  }

  ul {
    ${listReset};

    > li + li {
      margin-top: ${rem(32)};
    }
  }

  /* in aside (after logo) */
  a + ul {
    margin-top: ${rem(56)};
  }
`

const LogoLink = styled(Link)`
  && {
    margin: ${rem(-9)};
    margin-top: 0;
    padding: ${rem(9)};
  }

  * {
    pointer-events: none;
  }
`

export default function Navigation({ uid, logo, items, activeItem, navigate }) {
  const wrapperRef = React.useRef()
  const navRef = React.useRef()
  const indicatorY = useSpring(0, { damping: 20 })

  // FIXME: Atkārtoti mērījumi pēc resize
  const measurementsRef = React.useRef()
  React.useEffect(() => {
    const { top: wrapperTop } = wrapperRef.current.getBoundingClientRect()

    const logoLink = navRef.current.querySelector("a")
    const { height: logoHeight } = logoLink.getBoundingClientRect()
    const logoItemOffset = wrapperTop + (indicatorHeight - logoHeight) / 2

    const navLink = navRef.current.querySelector("ul a")
    const { height: linkHeight } = navLink.getBoundingClientRect()
    const listItemsOffset = wrapperTop + (indicatorHeight - linkHeight) / 2

    measurementsRef.current = {
      logoItemOffset,
      listItemsOffset,
    }
  }, [])

  // FIXME: n komponenti un render cikli (šobrīd 6)
  React.useEffect(() => {
    if (measurementsRef.current && navRef.current) {
      const targetLink = navRef.current.querySelector(
        `a[href="/#${activeItem}"]`
      )
      if (targetLink) {
        const isListItem = targetLink.parentNode.nodeName === "LI"
        const { logoItemOffset, listItemsOffset } = measurementsRef.current
        const offsetTop = isListItem ? listItemsOffset : logoItemOffset
        const { top: linkTop } = targetLink.getBoundingClientRect()
        indicatorY.set(Math.round(linkTop - offsetTop))
      }
    }
  }, [indicatorY, activeItem])

  function onLinkClick(event) {
    if (typeof window !== "undefined") {
      event.preventDefault()
      const link = event.target.getAttribute("href")
      const [, anchor] = link.split("#")
      navigate && navigate(anchor)
    }
  }

  const isCurrentlyActive = uid === activeItem
  const linkProps = {
    onClick: onLinkClick,
    tabIndex: isCurrentlyActive ? 0 : -1,
  }

  return (
    <Wrapper ref={wrapperRef}>
      <Indicator style={{ y: indicatorY }} />

      <Nav ref={navRef}>
        {logo && (
          <LogoLink to={`/#${logo.uid}`} title={logo.label} {...linkProps}>
            <Logo />
          </LogoLink>
        )}

        <ul>
          {items.map(({ uid, label }) => (
            <li key={uid}>
              <Link to={`/#${uid}`} {...linkProps}>
                {label}
              </Link>
            </li>
          ))}
        </ul>
      </Nav>
    </Wrapper>
  )
}
