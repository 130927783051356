import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { rem } from "./utils"
import Button from "./Button"
import Navigation from "./Navigation"
import Footer from "./Footer"

const barHeight = 70

const StyledMobileMenu = styled(motion.div)`
  background-color: ${({ theme }) => theme.background};
  display: flex;
  flex-direction: column;

  position: fixed;
  top: ${rem(barHeight)};
  left: 0;
  width: 100%;
  min-height: calc(100vh - ${rem(barHeight)});
  min-height: calc(-webkit-fill-available - ${rem(barHeight)});
  z-index: 101;

  > div {
    margin-top: ${rem(24)};
    margin-bottom: ${rem(24)};
  }

  > footer {
    margin-top: auto;
  }

  @media (max-height: ${rem(568)}) {
    font-size: 14px;

    > div > button {
      margin-top: 0;
    }
  }
`

const CtaWrapper = styled.div`
  padding: 0 ${rem(24)};

  > button {
    width: 100%;
  }
`

const variants = {
  initial: { opacity: 0, y: -50 },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 150,
      stiffness: 500,
    },
  },
}

export default function MobileMenu({ items, activeSection, navigate }) {
  const [, /* logoItem */ ...listItems] = items

  // const ref = React.useRef()
  // React.useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     const fullHeight = window.innerHeight
  //     ref.current.style.minHeight = `${fullHeight - barHeight}px`
  //   }
  // }, [])

  return (
    <StyledMobileMenu
      // ref={ref}
      initial="initial"
      animate="enter"
      variants={variants}
    >
      {/* FIXME: Intro sekcijā indikatoram nav atbilstošā pozīcijā */}
      <Navigation
        items={listItems}
        activeItem={activeSection}
        navigate={navigate}
      />

      <CtaWrapper>
        <Button
          onClick={() => {
            const lastItem = items[items.length - 1]
            navigate && navigate(lastItem.uid)
          }}
        >
          Get in touch
        </Button>
      </CtaWrapper>

      <Footer as="div" />
    </StyledMobileMenu>
  )
}
