import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import {
  motion,
  AnimatePresence,
  useViewportScroll,
  useTransform,
} from "framer-motion"
import debounce from "lodash.debounce"
import { rem, fluidValue } from "../utils"
import theme from "../theme"
import RichText from "../RichText"
import Timeline, { HorizontalTimeline } from "../Timeline"
import { useMotionDelay } from "../hooks"

export const FRAGMENT_How_we_do_it_section = graphql`
  fragment FRAGMENT_How_we_do_it_section on PRISMIC_How_we_do_it_section {
    _meta {
      uid
    }

    content

    timeline {
      time
      title
      description

      image
      imageSharp {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_noBase64
            presentationWidth
          }
        }
        publicURL
      }

      video {
        ... on PRISMIC__FileLink {
          url
        }
      }
    }

    theme
  }
`

const SectionLayout = styled.div`
  padding: 0 ${rem(24)} ${rem(24)};

  @media (min-width: ${rem(theme.screens.custom.timeline)}) {
    padding: ${rem(120)} ${rem(24)} ${rem(96)};
    height: ${({ numEntries }) => numEntries * 50}vh;
  }
`

const StickyContainer = styled.div`
  position: sticky;
  top: ${rem(24)};

  display: flex;
  flex-direction: column;

  @media (min-width: ${rem(theme.screens.custom.timeline)}) {
    flex-direction: row;
    top: ${rem(48)};
  }
`

const ContentGroup = styled.div``

const VideoWrapper = styled(motion.div)`
  margin-top: ${rem(32)};
  position: relative;
  padding-bottom: ${(390 / 510) * 100}%;
`

const ImageWrapper = styled(motion.div)`
  margin-top: ${rem(32)};
  position: relative;
  padding-bottom: ${(390 / 510) * 100}%;

  > div {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Video = styled(motion.video)`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const videoVariants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 1,
  },
}

const TimelineGroup = styled.div`
  flex: 0 0 auto;
  margin: ${rem(24)} 0;

  @media (min-width: ${rem(theme.screens.custom.timeline)}) {
    margin: 0;
    margin-left: ${fluidValue(
      24,
      70,
      theme.screens.custom.timeline,
      theme.screens.lg
    )};
  }
`

function useRelativeScrollProgress() {
  const [offset, setOffset] = React.useState({ top: 0, bottom: 0 })

  const measuredRef = React.useCallback(node => {
    if (node !== null) {
      // https://javascript.info/coordinates#getCoords
      const { top, height } = node.getBoundingClientRect()
      const { clientHeight } = document.documentElement
      setOffset({
        top: top + window.pageYOffset,
        bottom: top + height - clientHeight,
      })
    }
  }, [])

  const { scrollY } = useViewportScroll()

  const relativeYProgress = useTransform(
    scrollY,
    [offset.top, offset.bottom],
    [0, 1]
  )

  return [measuredRef, relativeYProgress]
}

const HowWeDoItMobileSection = React.memo(function HowWeDoItMobileSection({
  data,
}) {
  const { content, timeline } = data

  const [activeIndex, setActiveIndex] = React.useState(0)
  const entry = timeline[activeIndex]

  const motionDelay = useMotionDelay()
  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  return (
    <SectionLayout>
      <ContentGroup>
        <RichText render={content} />

        <motion.div
          ref={ref}
          initial="initial"
          animate={inView ? "enter" : "initial"}
          variants={theme.motion.contentVariants}
          custom={motionDelay}
        >
          {entry && (
            <AnimatePresence exitBeforeEnter>
              {entry.video ? (
                <VideoWrapper
                  key={entry.title}
                  initial="initial"
                  animate="enter"
                  exit="exit"
                  variants={videoVariants}
                >
                  <Video
                    key={entry.title}
                    initial="initial"
                    animate="enter"
                    exit="exit"
                    variants={videoVariants}
                    loop
                    muted
                    autoPlay
                    playsInline
                    disablePictureInPicture
                    poster={
                      entry.imageSharp
                        ? entry.imageSharp.publicURL
                        : entry.image && entry.image.url
                    }
                    preload="metadata"
                  >
                    {entry.video && (
                      <source src={entry.video.link.url} type="video/mp4" />
                    )}
                  </Video>
                </VideoWrapper>
              ) : (
                <ImageWrapper
                  key={entry.title}
                  initial="initial"
                  animate="enter"
                  exit="exit"
                  variants={videoVariants}
                >
                  {entry.imageSharp && entry.imageSharp.childImageSharp ? (
                    <Img
                      key={entry.title}
                      fluid={entry.imageSharp.childImageSharp.fluid}
                    />
                  ) : entry.image ? (
                    <img key={entry.title} src={entry.image.url} alt="" />
                  ) : null}
                </ImageWrapper>
              )}
            </AnimatePresence>
          )}
        </motion.div>
      </ContentGroup>

      <TimelineGroup>
        <motion.div
          initial="initial"
          animate={inView ? "enter" : "initial"}
          variants={theme.motion.contentVariants}
          custom={motionDelay}
        >
          <HorizontalTimeline
            data={timeline}
            activeIndex={activeIndex}
            onChange={setActiveIndex}
          />
        </motion.div>
      </TimelineGroup>
    </SectionLayout>
  )
})

const HowWeDoItDesktopSection = React.memo(function HowWeDoItDesktopSection({
  data,
}) {
  const { content, timeline } = data

  const [activeIndex, setActiveIndex] = React.useState(0)
  const entry = timeline[activeIndex]

  const motionDelay = useMotionDelay()
  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  const [sectionRef, scrollYProgress] = useRelativeScrollProgress()

  const numEntries = timeline.length
  const scrollStep = numEntries > 0 ? 1 / numEntries : 1

  React.useEffect(() => {
    return scrollYProgress.onChange(latestScrollYProgress => {
      const i = Math.floor(latestScrollYProgress / scrollStep)
      if (i < numEntries) {
        setActiveIndex(i)
      }
    })
  }, [scrollYProgress, scrollStep, numEntries])

  return (
    <SectionLayout ref={sectionRef} numEntries={numEntries}>
      <StickyContainer>
        <ContentGroup>
          <RichText render={content} />

          <motion.div
            ref={ref}
            initial="initial"
            animate={inView ? "enter" : "initial"}
            variants={theme.motion.contentVariants}
            custom={motionDelay}
          >
            {entry && (
              <AnimatePresence exitBeforeEnter>
                {entry.video ? (
                  <VideoWrapper
                    key={entry.title}
                    initial="initial"
                    animate="enter"
                    exit="exit"
                    variants={videoVariants}
                  >
                    <Video
                      loop
                      muted
                      autoPlay
                      playsInline
                      disablePictureInPicture
                      poster={
                        entry.imageSharp
                          ? entry.imageSharp.publicURL
                          : entry.image && entry.image.url
                      }
                      preload="metadata"
                    >
                      {entry.video && (
                        <source src={entry.video.link.url} type="video/mp4" />
                      )}
                    </Video>
                  </VideoWrapper>
                ) : (
                  <ImageWrapper
                    key={entry.title}
                    initial="initial"
                    animate="enter"
                    exit="exit"
                    variants={videoVariants}
                  >
                    {entry.imageSharp && entry.imageSharp.childImageSharp ? (
                      <Img
                        key={entry.title}
                        fluid={entry.imageSharp.childImageSharp.fluid}
                      />
                    ) : entry.image ? (
                      <img key={entry.title} src={entry.image.url} alt="" />
                    ) : null}
                  </ImageWrapper>
                )}
              </AnimatePresence>
            )}
          </motion.div>
        </ContentGroup>

        <TimelineGroup>
          <motion.div
            initial="initial"
            animate={inView ? "enter" : "initial"}
            variants={theme.motion.contentVariants}
            custom={motionDelay}
          >
            <Timeline
              data={timeline}
              activeIndex={activeIndex}
              scrollYProgress={scrollYProgress}
            />
          </motion.div>
        </TimelineGroup>
      </StickyContainer>
    </SectionLayout>
  )
})

export default function BreakpointSwitch(props) {
  const [widerThanSmallScreen, setWiderThanSmallScreen] = React.useState(() => {
    const breakpoint =
      typeof window !== "undefined" &&
      window.matchMedia(`(min-width: ${rem(theme.screens.custom.timeline)})`)
    return breakpoint && breakpoint.matches
  })

  React.useEffect(() => {
    function updateBreakpoint() {
      const breakpoint =
        typeof window !== "undefined" &&
        window.matchMedia(`(min-width: ${rem(theme.screens.custom.timeline)})`)
      setWiderThanSmallScreen(breakpoint && breakpoint.matches)
    }

    const onResizeThrottled = debounce(updateBreakpoint, 250)

    if (typeof window !== "undefined") {
      window.addEventListener("resize", onResizeThrottled)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", onResizeThrottled)
      }
    }
  }, [])

  // Risina position: sticky un overflow problēmu
  const uid = props.data._meta.uid
  React.useEffect(() => {
    const section = document.body.querySelector(`section[data-key="${uid}"]`)
    if (section) {
      section.setAttribute("data-sticky", widerThanSmallScreen)
    }
  }, [widerThanSmallScreen, uid])

  return widerThanSmallScreen ? (
    <HowWeDoItDesktopSection {...props} />
  ) : (
    <HowWeDoItMobileSection {...props} />
  )
}
