import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { listReset } from "./reset"
import { rem } from "./utils"
import theme from "./theme"
import ContactLink from "./ContactLink"

const StyledPersonCard = styled.li`
  display: flex;
  align-items: center;
`

const ImgWrapper = styled.div`
  img {
    display: block;
    border-radius: ${rem(5)};
  }

  .gatsby-image-wrapper {
    width: ${rem(110)} !important;
    height: ${rem(110)} !important;
  }
`

const ContentWrapper = styled.div`
  margin-left: ${rem(24)};

  h4 {
    color: ${({ theme }) => theme.text.heading};
    font-size: ${rem(16)};
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0.75em;
  }

  p {
    color: ${({ theme }) => theme.text.body};
    margin: 0;
  }

  ul {
    ${listReset};

    display: flex;
    margin-top: ${rem(16)};

    > li + li {
      margin-left: ${rem(8)};
    }

    a {
      color: ${({ theme }) => theme.text.body};
      text-decoration: none;

      &:hover {
        color: ${theme.colors.accent};
      }
    }
  }
`

export default function PersonCard({ data }) {
  const { full_name, position, picture, pictureSharp, contacts } = data
  return (
    <StyledPersonCard>
      <ImgWrapper>
        {pictureSharp && pictureSharp.childImageSharp ? (
          <Img fixed={pictureSharp.childImageSharp.fixed} />
        ) : (
          <img src={picture.url} alt="" />
        )}
      </ImgWrapper>

      <ContentWrapper>
        <h4>{full_name}</h4>
        <p>{position}</p>
        <ul>
          {contacts.map(contact => {
            const { type, link } = contact
            if (link) {
              return (
                <li key={link.url}>
                  <ContactLink type={type} link={link} />
                </li>
              )
            }
            return null
          })}
        </ul>
      </ContentWrapper>
    </StyledPersonCard>
  )
}
