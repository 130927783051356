import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { rem } from "./utils"
import theme from "./theme"
import ShapeExclude from "./ShapeExclude"

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.background};
  position: relative;
  overflow: hidden;

  > div {
    background-color: ${({ theme }) => theme.background};
  }

  > svg {
    display: none;
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
  }

  img,
  video {
    display: block;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    > svg {
      display: block;
      position: absolute;
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
    }

    img,
    video {
      padding: 2px;
    }
  }
`

export default function ShapeClipPathExclude({ image, imageSharp, video }) {
  return (
    <Wrapper>
      {video ? (
        <div className="video-wrapper">
          <video
            loop
            muted
            autoPlay
            playsInline
            disablePictureInPicture
            poster={imageSharp ? imageSharp.publicURL : image && image.url}
          >
            <source src={video.url} type="video/mp4" />
          </video>
        </div>
      ) : imageSharp && imageSharp.childImageSharp ? (
        <Img fluid={imageSharp.childImageSharp.fluid} />
      ) : image ? (
        <img src={image.url} alt="" />
      ) : null}

      <ShapeExclude />
    </Wrapper>
  )
}
