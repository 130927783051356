import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { motion } from "framer-motion"
import { rem } from "../utils"
import theme from "../theme"
import RichText from "../RichText"
import Button from "../Button"
import ShapeClipPathExclude from "../ShapeClipPathExclude"

export const FRAGMENT_Intro_section = graphql`
  fragment FRAGMENT_Intro_section on PRISMIC_Intro_section {
    _meta {
      uid
    }

    content

    image
    imageSharp {
      publicURL
      childImageSharp {
        fluid(quality: 90, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    video {
      ... on PRISMIC__FileLink {
        url
      }
    }

    theme
  }
`

const SectionLayout = styled.div`
  padding: ${rem(48)} ${rem(24)};

  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media (min-width: ${rem(theme.screens.sm)}) {
    flex-direction: row;
    align-items: center;

    padding: ${rem(96)} ${rem(24)} ${rem(48)};
  }
`

const ContentGroup = styled.div`
  position: relative;
  z-index: 1;
  text-shadow: 1px 1px 1px ${({ theme }) => theme.background};

  button {
    width: 100%;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    button {
      width: auto;
    }
  }
`

const MediaGroup = styled.div`
  margin: ${rem(48)} ${rem(-24)} 0;
  overflow: visible;

  svg {
    display: none;
  }

  .video-wrapper {
    padding-bottom: ${(2 / 3) * 100}%;
    position: relative;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .gatsby-image-wrapper > div {
    padding-bottom: ${(2 / 3) * 100}% !important;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: ${rem(1014)};
    transform: translate(calc(100% - 40vw), -20%);

    svg {
      display: block;
    }

    /* .video-wrapper,
    .gatsby-image-wrapper {
      -webkit-clip-path: url(#intro-media-clipping);
      clip-path: url(#intro-media-clipping);
    } */

    .video-wrapper {
      padding-bottom: 100%;
    }

    .gatsby-image-wrapper > div {
      padding-bottom: 100% !important;
    }
  }
`

const mediaVariants = {
  initial: { opacity: 0 },
  enter: {
    opacity: 1,
    transition: {
      delay: 1.75,
      type: "spring",
      damping: 250,
      stiffness: 25,
    },
  },
}

export default function IntroSection({ data }) {
  const { content, image, imageSharp, video } = data

  return (
    <SectionLayout>
      <ContentGroup>
        <RichText render={content} />
        <motion.div
          initial="initial"
          animate="enter"
          variants={theme.motion.contentVariants}
          custom={{ delay: 1.5 }}
        >
          <Button
            onClick={() => {
              const uid = "contacts"
              const section = document.body.querySelector(
                `section[data-key="${uid}"]`
              )
              if (section) section.scrollIntoView()
            }}
          >
            Get in touch
          </Button>
        </motion.div>
      </ContentGroup>

      <MediaGroup>
        <motion.div initial="initial" animate="enter" variants={mediaVariants}>
          <ShapeClipPathExclude image={image} imageSharp={imageSharp} video={video} />
        </motion.div>
      </MediaGroup>
    </SectionLayout>
  )
}
