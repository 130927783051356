import React from "react"
import styled from "styled-components"
import theme from "./theme"

const StyledLink = styled.a`
  color: ${theme.colors.accent};
  display: inline-flex;
  align-items: center;
  margin: -0.5em;
  padding: 0.5em;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const Icon = styled.i`
  display: block;

  svg {
    display: block;
  }
`

function getIconByType(type) {
  switch (type) {
    case "LinkedIn":
      return (
        <svg
          width="28"
          height="28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.5 10a5.48 5.48 0 013.89 1.622A5.558 5.558 0 0123 15.54V22h-3.667v-6.461c0-.49-.193-.96-.537-1.306a1.827 1.827 0 00-2.592 0 1.853 1.853 0 00-.537 1.306V22H12v-6.461c0-1.47.58-2.878 1.61-3.917A5.48 5.48 0 0117.5 10v0zM9 11H5v11h4V11zM7 9a2 2 0 100-4 2 2 0 000 4z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )

    case "E-mail":
    default:
      return (
        <svg
          width="28"
          height="28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.8 7h14.4c.99 0 1.8.787 1.8 1.75v10.5c0 .962-.81 1.75-1.8 1.75H6.8c-.99 0-1.8-.788-1.8-1.75V8.75C5 7.787 5.81 7 6.8 7z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23 9l-9 6-9-6"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
  }
}

function getTitleByType(type) {
  switch (type) {
    default:
      return type
  }
}

export default function ContactLink({ type, link }) {
  if (link && link.url) {
    return (
      <StyledLink
        href={link.url}
        title={getTitleByType(type)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon>{getIconByType(type)}</Icon>
      </StyledLink>
    )
  }
  return null
}
