import React from "react"
import styled, { css } from "styled-components"
import { useInView } from "react-intersection-observer"
import { rem } from "../components/utils"

const StyledSentinel = styled.div`
  background-color: green;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${rem(40)};
  opacity: 0.25;
  visibility: hidden;

  ${({ bottom }) =>
    bottom &&
    css`
      background-color: red;
      top: auto;
      bottom: 0;
      height: ${rem(80)};
    `};
`

export default function Sentinel({ uid, bottom, onChange }) {
  const [ref /* inView */, , entry] = useInView({
    triggerOnce: false,
    rootMargin: "-50% 0% 0%",
    threshold: bottom ? 1 : 0,
  })

  if (entry) {
    const { boundingClientRect, rootBounds, intersectionRatio } = entry

    if (bottom) {
      if (
        boundingClientRect.bottom > rootBounds.top &&
        intersectionRatio === 1
      ) {
        // console.log(`Active: ${uid}`)
        onChange && onChange(uid)
      }

      // if (
      //   boundingClientRect.top < rootBounds.top &&
      //   boundingClientRect.bottom < rootBounds.bottom
      // ) {
      //   console.log(`Not active: ${uid}`)
      // }
    } else {
      if (boundingClientRect.bottom < rootBounds.top) {
        // console.log(`Active: ${uid}`)
        onChange && onChange(uid)
      }

      // if (
      //   boundingClientRect.bottom >= rootBounds.top &&
      //   boundingClientRect.bottom < rootBounds.bottom
      // ) {
      //   console.log(`Not active: ${uid}`)
      // }
    }
  }

  return <StyledSentinel ref={ref} bottom={bottom} />
}
