import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { withPreview } from "gatsby-source-prismic-graphql"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import { motion } from "framer-motion"
import { listReset } from "../reset"
import { rem } from "../utils"
import theme from "../theme"
import RichText from "../RichText"
import ButtonGroup from "../ButtonGroup"
import { useMotionDelay } from "../hooks"

export const FRAGMENT_Use_cases_section = graphql`
  fragment FRAGMENT_Use_cases_section on PRISMIC_Use_cases_section {
    _meta {
      uid
    }

    content

    # functions {
    #   icon
    #   iconSharp {
    #     childImageSharp {
    #       fixed(quality: 90, width: 58, height: 58) {
    #         ...GatsbyImageSharpFixed_noBase64
    #       }
    #     }
    #     publicURL
    #   }
    #   name
    #   solution_category {
    #     ... on PRISMIC_Solution_category {
    #       name
    #       _meta {
    #         uid
    #       }
    #     }
    #   }
    # }

    body {
      ... on PRISMIC_Use_cases_sectionBodyFunctions {
        primary {
          name
          icon
          iconSharp {
            childImageSharp {
              fixed(quality: 90, width: 58, height: 58) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
            publicURL
          }
        }

        fields {
          solution_category {
            ... on PRISMIC_Solution_category {
              _meta {
                uid
              }
              name
            }
          }
        }
      }
    }

    theme
  }
`

const solutionCategoriesQuery = graphql`
  query {
    prismic {
      allSolution_categorys(sortBy: order_ASC) {
        edges {
          node {
            _meta {
              uid
            }
            name
          }
        }
      }
    }
  }
`

const SectionLayout = styled.div`
  padding: 0 ${rem(24)} ${rem(24)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding: ${rem(120)} ${rem(24)} ${rem(96)};
  }
`

const ButtonGroupWrapper = styled.div`
  margin: ${rem(40)} ${rem(-24)} 0;

  > * {
    padding: 0 ${rem(24)};
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    margin: ${rem(40)} 0 0;

    > * {
      padding: 0;
    }
  }
`

const FunctionsList = styled(motion.ul)`
  ${listReset};

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: ${rem(-24)} ${rem(-32)};
  margin-top: ${rem(18)};

  > li {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:last-child {
      margin-left: ${rem(24)};
      margin-right: auto;
    }

    padding: ${rem(20)} ${rem(26)};
    box-sizing: content-box;
    max-width: ${rem(120)};

    img {
      width: ${rem(58)};
      height: ${rem(58)};
    }

    span {
      color: ${({ theme }) => theme.button.text.default};
      display: inline-block;
      font-size: ${rem(14)};
      line-height: 115%;
      margin-top: ${rem(8)};
      text-align: center;
    }
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    justify-content: flex-start;

    > li {
      margin: 0;

      &:last-child {
        margin-left: 0;
      }
    }
  }
`

const FunctionItem = styled(motion.li)``

const functionItemVariants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  enter: ({ index }) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.05 * index,
      type: "spring",
      damping: 25,
      stiffness: 150 + 50 * index,
    },
  }),
}

const functionItemSpringTransition = {
  type: "spring",
  damping: 100,
  stiffness: 250,
}

export default function UseCasesSection({ data }) {
  const { content, body } = data

  const [category, setCategory] = React.useState(null)

  const filteredFunctions = React.useMemo(() => {
    if (category) {
      return body
        .filter(
          ({ fields }) =>
            fields &&
            fields.some(
              ({ solution_category }) =>
                solution_category && solution_category._meta.uid === category
            )
        )
        .map(({ primary }) => ({
          name: primary.name,
          icon: primary.icon,
          iconSharp: primary.iconSharp,
        }))

      // NOTE: Now with slices (implemented above)
      // return functions.filter(
      //   ({ solution_category }) =>
      //     solution_category && solution_category._meta.uid === category
      // )
    }
    return body.map(({ primary }) => ({
      name: primary.name,
      icon: primary.icon,
      iconSharp: primary.iconSharp,
    }))
  }, [body, category])

  const motionDelay = useMotionDelay()
  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  return (
    <SectionLayout>
      <RichText render={content} wide />

      <motion.div
        ref={ref}
        initial="initial"
        animate={inView ? "enter" : "initial"}
        variants={theme.motion.contentVariants}
        custom={motionDelay}
      >
        <StaticQuery
          query={solutionCategoriesQuery}
          render={withPreview(data => {
            const solutionCategories = data.prismic.allSolution_categorys.edges
            const groupCategories = [
              {
                key: "all",
                label: "All",
                reset: true,
              },
              ...solutionCategories.map(({ node }) => {
                const { _meta, name } = node
                return {
                  key: _meta.uid,
                  label: name,
                }
              }),
            ]
            return (
              <ButtonGroupWrapper>
                <ButtonGroup
                  categories={groupCategories}
                  active={category}
                  onChange={setCategory}
                />
              </ButtonGroupWrapper>
            )
          }, solutionCategoriesQuery)}
        />

        <FunctionsList>
          {filteredFunctions.map(({ icon, iconSharp, name }, index) => (
            <FunctionItem
              key={name}
              initial="initial"
              animate="enter"
              variants={functionItemVariants}
              positionTransition={functionItemSpringTransition}
              custom={{ index }}
            >
              {iconSharp && iconSharp.childImageSharp ? (
                <Img fixed={iconSharp.childImageSharp.fixed} />
              ) : (
                <img src={icon.url} alt="" />
              )}
              <span>{name}</span>
            </FunctionItem>
          ))}
        </FunctionsList>
      </motion.div>
    </SectionLayout>
  )
}
