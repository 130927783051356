import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { withPreview } from "gatsby-source-prismic-graphql"
import Img from "gatsby-image"
import styled from "styled-components"
import { rem } from "./utils"
import theme from "./theme"
import ExternalLink from "./ExternalLink"

const StyledFooter = styled.footer`
  display: flex;

  background-color: ${({ theme }) => theme.background};
  border-top: 1px solid ${({ theme }) => theme.button.border};
  margin-top: ${rem(32)};
  padding: ${rem(32)} ${rem(24)};

  @media (min-width: ${rem(theme.screens.sm)}) {
    align-items: center;
    justify-content: center;
  }
`

const ContentGroup = styled.div`
  p {
    color: ${({ theme }) => theme.text.body};
    line-height: 150%;
    margin-top: 0;
    margin-bottom: 0.5em;
  }

  @media (min-width: ${rem(theme.screens.sm)}) {
    display: flex;
    align-items: center;

    p {
      margin-bottom: 0;
    }

    a {
      margin: 0;
      white-space: nowrap;
    }
  }
`

const GraphicGroup = styled.div`
  img {
    filter: invert(${({ theme }) => (theme.id === "light" ? 1 : 0)});
  }
`

const query = graphql`
  query {
    file(relativePath: { eq: "footer-graphics.png" }) {
      childImageSharp {
        fixed(width: 110, height: 110) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`

export default function Footer({ className, style }) {
  return (
    <StyledFooter className={className} style={style}>
      <ContentGroup>
        <p>This project is a part of Mobility branch at LMT R&D platform.</p>
        <ExternalLink to="https://innovations.lmt.lv/en/" label="Check out other products" />
      </ContentGroup>

      <GraphicGroup>
        <StaticQuery
          query={query}
          render={withPreview(data => {
            return <Img fixed={data.file.childImageSharp.fixed} />
          }, query)}
        />
      </GraphicGroup>
    </StyledFooter>
  )
}
