import React from "react"
import styled from "styled-components"
import { rem } from "./utils"
import theme from "./theme"

const StyledLink = styled.a`
  color: ${theme.colors.accent};
  display: inline-flex;
  align-items: center;
  margin: -0.5em;
  padding: 0.5em;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const Icon = styled.i`
  display: inline-block;
  margin-left: ${rem(8)};
`

export default function ExternalLink({ to }) {
  return (
    <StyledLink href={to} target="_blank" rel="noopener noreferrer">
      Check out other products{" "}
      <Icon>
        <svg
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 13.133v4.4A1.466 1.466 0 0114.533 19H6.467A1.466 1.466 0 015 17.533V9.467A1.467 1.467 0 016.467 8h4.4M15 4h5v5M11 13l9-9"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Icon>
    </StyledLink>
  )
}
