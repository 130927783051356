import React from "react"
import styled, { css } from "styled-components"
import { motion } from "framer-motion"
import debounce from "lodash.debounce"
import { listReset } from "./reset"
import { rem } from "./utils"
import theme from "./theme"

const StyledTimeline = styled.ul`
  ${listReset};
  margin: 0 ${rem(-24)};

  ${({ horizontal }) =>
    horizontal &&
    css`
      display: flex;
      padding: 0 ${rem(24)} ${rem(12)};
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
    `};

  @media (min-width: ${rem(theme.screens.custom.timeline)}) {
    margin: 0;
  }
`

const TimeEntry = styled.li`
  flex: 0 0 auto;
  display: flex;
  scroll-snap-align: start;
  scroll-margin-left: ${rem(24)};
  width: ${rem(186)};

  & + & {
    margin-left: ${rem(40)};
  }

  &:last-child {
    border-right: ${rem(24)} solid transparent;
  }

  ${({ horizontal }) =>
    horizontal &&
    css`
      flex-direction: column;
    `};

  ${({ isActive }) =>
    isActive &&
    css`
      p,
      span {
        color: ${({ theme }) => theme.timeline.text.body.active};
      }
    `};

  @media (min-width: ${rem(theme.screens.custom.timeline)}) {
    width: auto;

    & + & {
      margin-top: ${rem(16)};
      margin-left: 0;
    }

    &:last-child {
      border: none;
    }
  }
`

const Row = styled.div`
  display: flex;
`

const Time = styled.span`
  color: ${({ theme }) => theme.timeline.text.body.default};
  display: block;
  font-size: ${rem(16)};
  margin-top: ${rem(12)};
  white-space: nowrap;

  @media (min-width: ${rem(theme.screens.custom.timeline)}) {
    margin-top: ${rem(1)};
    margin-right: ${rem(14)};
    text-align: right;
    width: ${rem(40)};
  }
`

const Title = styled(motion.h4)`
  color: ${({ theme }) => theme.timeline.text.heading.active};
  font-size: ${rem(20)};
  margin-top: ${rem(12)};
  margin-bottom: ${rem(8)};
  transform-origin: top left;

  @media (min-width: ${rem(theme.screens.custom.timeline)}) {
    margin-top: 0;
    margin-left: ${rem(24)};
  }
`

const Description = styled.p`
  color: ${({ theme }) => theme.timeline.text.body.default};
  font-size: ${rem(16)};
  margin: 0;
  line-height: 150%;

  @media (min-width: ${rem(theme.screens.custom.timeline)}) {
    margin-left: ${rem(24)};
    max-width: ${rem(280)};
  }
`

const Check = styled.span`
  background-color: ${({ theme }) => theme.highlight.border};
  border: 2px solid transparent;
  border-radius: ${rem(6)};
  box-sizing: content-box;
  display: inline-block;
  width: ${rem(16)};
  height: ${rem(16)};

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${theme.colors.accent};
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAZCAYAAABU+vysAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD7SURBVHgBzZVtEYMwDIYzB5NQCZMyCTgYDsABSJgDJCABCUiYhHfp0d2VXDvGV7Lnjjv+pHn6kYToT7iQMQAKsoYlKgTIiljCTITz3jHnSdpwUsffK5IY+LuSJkFijCT8vyNN/K7NJYJIL97FjbThpI2QKEkbWaZMTdpw0oeQaJcCPq95POru/DpCov8lqI4CfI3faQdIl+lyr0gEbn5Q2NsrMjIVrQBTrxjE6TpaS0amWRHfidjtVxx2JRfslu4YZ5UpL9SKhYfcMePsXoF5NQGJhwetkf5NBomRTmfCCQqR0P+XsJimmDrliDQ6EpGMy8iYjHQpc8hIfwMrOKnH1NmYZwAAAABJRU5ErkJggg==");
      background-size: ${rem(10)} ${rem(7)};
      background-position: center center;
      background-repeat: no-repeat;
      border-color: ${theme.colors.accent};
    `};
`

const LineWrapper = styled.div`
  margin: 0 ${rem(8)};

  svg rect {
    fill: ${({ theme }) => theme.highlight.border};
  }
  svg rect:nth-child(-n + ${({ activeIndex }) => activeIndex}) {
    fill: ${theme.colors.accent};
  }

  ${({ horizontal }) =>
    horizontal &&
    css`
      margin: ${rem(8)} 0;
      margin-left: ${rem(36)};
    `};
`

const LineSpacer = styled.div`
  margin: 0 ${rem(8)};
  width: ${rem(4)};
`

function Line({ activeIndex }) {
  return (
    <LineWrapper activeIndex={activeIndex}>
      <svg
        width="4"
        height="64"
        viewBox="0 0 4 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="4" height="4" rx="1" fill="#C8102E" />
        <rect y="20" width="4" height="4" rx="1" fill="#C8102E" />
        <rect y="40" width="4" height="4" rx="1" fill="#C8102E" />
        <rect y="60" width="4" height="4" rx="1" fill="#E8E8E8" />
      </svg>
    </LineWrapper>
  )
}

function HorizontalLine({ activeIndex }) {
  return (
    <LineWrapper activeIndex={activeIndex} horizontal>
      <svg
        width="130"
        height="4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="4"
          width="4"
          height="4"
          rx="1"
          transform="rotate(-90 0 4)"
          fill="#C8102E"
        />
        <rect
          x="42"
          y="4"
          width="4"
          height="4"
          rx="1"
          transform="rotate(-90 42 4)"
          fill="#C8102E"
        />
        <rect
          x="84"
          y="4"
          width="4"
          height="4"
          rx="1"
          transform="rotate(-90 84 4)"
          fill="#E8E8E8"
        />
        <rect
          x="126"
          y="4"
          width="4"
          height="4"
          rx="1"
          transform="rotate(-90 126 4)"
          fill="#E8E8E8"
        />
      </svg>
    </LineWrapper>
  )
}

const titleVariants = {
  initial: {
    opacity: 0.8,
    scale: 0.8,
    y: 1,
  },
  active: {
    opacity: 1,
    scale: 1,
    y: -2,
    transition: {
      type: "spring",
      damping: 150,
      stiffness: 500,
    },
  },
}

export default function Timeline({ data, activeIndex, scrollYProgress }) {
  const numEntries = data.length
  const scrollStep = numEntries > 0 ? 1 / (numEntries * 4) : 1

  const [subIndex, setSubIndex] = React.useState(activeIndex * 4)

  React.useEffect(() => {
    return scrollYProgress.onChange(latestScrollYProgress => {
      const i = Math.floor(latestScrollYProgress / scrollStep)
      setSubIndex(i)
    })
  }, [scrollYProgress, scrollStep])

  return (
    <StyledTimeline>
      {data.map((entry, i) => {
        const { time, title, description } = entry
        const isActive = activeIndex >= i
        const notLast = i < data.length - 1

        let relSubIndex = 0
        if (isActive) {
          relSubIndex = subIndex - i * 4
        }

        return (
          <TimeEntry key={title} isActive={isActive}>
            <Time>{time}</Time>
            <div>
              <Row>
                <Check isActive={isActive} />
                <Title
                  initial="initial"
                  animate={isActive ? "active" : "initial"}
                  variants={titleVariants}
                >
                  {title}
                </Title>
              </Row>
              <Row>
                {notLast ? <Line activeIndex={relSubIndex} /> : <LineSpacer />}
                <Description>{description}</Description>
              </Row>
            </div>
          </TimeEntry>
        )
      })}
    </StyledTimeline>
  )
}

export function HorizontalTimeline({ data, activeIndex, onChange }) {
  const numEntries = data.length

  const ref = React.useRef()
  React.useEffect(() => {
    const target = ref.current

    const { width } = target.getBoundingClientRect()
    const stepWidth = (target.scrollWidth - width) / numEntries

    function updateScrollSnap(event) {
      const { target } = event
      const i = Math.floor(
        (target.scrollLeft + 24) /* scroll-margin? */ / stepWidth
      )
      if (i < numEntries && onChange) {
        onChange(i)
      } else if (onChange) {
        onChange(numEntries - 1)
        const lastItem = target.querySelector("li:nth-last-child(2)")
        if (lastItem) {
          target.scrollTo({
            left: lastItem.offsetLeft,
            top: 0,
            behavior: "smooth",
          })
        }
      }
    }

    const onScrollThrottled = debounce(updateScrollSnap, 250)
    target.addEventListener("scroll", onScrollThrottled, { passive: true })

    return () => {
      target.removeEventListener("scroll", onScrollThrottled)
    }
  }, [numEntries, onChange])

  return (
    <StyledTimeline ref={ref} horizontal>
      {data.map((entry, i) => {
        const { time, title, description } = entry
        const isActive = activeIndex >= i
        const notLast = i < data.length - 1
        const isLineActive = activeIndex > i ? 4 : 0

        return (
          <TimeEntry
            key={title}
            isActive={isActive}
            onClick={() => {
              const item = ref.current.querySelector(`li:nth-child(${i + 1})`)
              if (item) {
                ref.current.scrollTo({
                  left: item.offsetLeft,
                  top: 0,
                  behavior: "smooth",
                })
              }
              onChange && onChange(i)
            }}
            horizontal
          >
            <Row>
              <Check isActive={isActive} />
              {notLast ? <HorizontalLine activeIndex={isLineActive} /> : null}
            </Row>
            <Row style={{ justifyContent: "space-between" }}>
              <Title
                initial="initial"
                animate={isActive ? "active" : "initial"}
                variants={titleVariants}
              >
                {title}
              </Title>
              <Time>{time}</Time>
            </Row>
            <Row>
              <Description>{description}</Description>
            </Row>
          </TimeEntry>
        )
      })}

      {/* Empty entry */}
      <TimeEntry key={"empty"} horizontal />
    </StyledTimeline>
  )
}
